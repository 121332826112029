import { graphql } from 'gatsby';
import React from 'react';

import Grid from 'components/pages/blog/grid';
import Pagination from 'components/pages/blog/pagination';
import Categories from 'components/shared/categories';
import FeaturedPost from 'components/shared/featured-post';
import Subscribe from 'components/shared/subscribe/subscribe';
import MainLayout from 'layouts/main';

const Blog = ({
  data: {
    wpPage: {
      seo,
      uri,
      acf: { hero: featuredPost },
    },
    allWpPost: { nodes: posts },
  },
  pageContext,
}) => (
  <MainLayout seo={seo} pageContext={pageContext}>
    <FeaturedPost {...featuredPost} />
    {pageContext.categories.length > 1 && (
      <Categories
        categories={pageContext.categories}
        pageRootPath={uri}
        activeCategoryId={pageContext.categoryId}
      />
    )}
    <Grid items={posts} />
    {pageContext.pageCount > 1 && (
      <Pagination
        pageCount={pageContext.pageCount}
        currentPageIndex={pageContext.currentPage}
        pageRootPath={uri}
        categoryPath={pageContext.categoryPath}
      />
    )}
    <Subscribe />
  </MainLayout>
);

export default Blog;

export const query = graphql`
  query ($id: String, $featuredPostId: String, $categoryId: String, $skip: Int, $limit: Int) {
    wpPage(id: { eq: $id }) {
      title
      uri
      acf {
        hero {
          title
          description
          featuredPost {
            ... on WpPost {
              title
              author {
                node {
                  name
                }
              }
              readingTime
              categories {
                nodes {
                  acf {
                    color
                  }
                  name
                }
              }
              acf {
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                description
              }
              url: uri
            }
          }
        }
      }
      ...wpPageSeo
    }
    allWpPost(
      filter: {
        id: { ne: $featuredPostId }
        categories: { nodes: { elemMatch: { id: { eq: $categoryId } } } }
        acf: { private: { eq: null } }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        title
        readingTime
        author {
          node {
            name
          }
        }
        categories {
          nodes {
            acf {
              color
            }
            name
          }
        }
        acf {
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          description
        }
        url: uri
      }
    }
  }
`;
