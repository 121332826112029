import classNames from 'classnames';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Container from 'components/shared/container';
import Link from 'components/shared/link';
import ArrowBottom from 'icons/arrow-bottom.inline.svg';

const Categories = ({ categories, pageRootPath, activeCategoryId }) => {
  const [userClickCategoryId, setUserClickCategoryId] = useState();

  const animateScroll = () => {
    // eslint-disable-next-line global-require
    require('smooth-scroll')().animateScroll(document.querySelector(`.categories`), undefined, {
      speed: 500,
      offset: 90,
    });
  };

  const navigateOnScrollEnd = (href) => {
    const changeUrl = () => {
      navigate(href, {
        state: { preventScroll: true },
      });
      document.removeEventListener('scrollStop', changeUrl, false);
    };
    document.addEventListener('scrollStop', changeUrl, false);
  };

  const handleClick = (categoryId, event) => {
    event.preventDefault();
    const href = event.currentTarget.getAttribute('href');
    setUserClickCategoryId(categoryId);
    navigateOnScrollEnd(href);
    animateScroll();
  };

  const handleSelectChange = (event) => {
    const categoryId = event.currentTarget.value;
    if (categoryId === 'all') {
      navigateOnScrollEnd(pageRootPath);
      animateScroll();
      return;
    }

    const categorySlug = categories.find((category) => category.id === categoryId).slug;
    navigateOnScrollEnd(pageRootPath + categorySlug);
    animateScroll();
  };

  const selectValue = activeCategoryId || 'all';
  const selectedCategoryName =
    selectValue === 'all'
      ? 'All Articles'
      : categories.find((category) => category.id === selectValue).name;

  const wrapperItemsClassNames = classNames(
    'relative col-start-1 col-end-13 xl:col-start-2 xl:col-end-12 hidden gap-x-8 2xl:gap-x-14',
    'before:absolute before:left-0 before:bottom-0 before:w-full before:h-0.5 before:bg-gray-1',
    {
      'lg:flex': categories.length > 4,
      'md:flex': categories.length <= 4,
    }
  );
  const itemClassNames =
    'relative block py-5 text-lg lg:text-xl font-bold transition-colors duration-200 hover:text-primary-2';
  const itemActiveClassNames =
    'text-primary-2 after:absolute after:left-0 after:bottom-0 after:w-full after:h-1 after:bg-primary-2';

  return (
    <div className="categories pt-5 md:pt-11">
      <Container>
        <div className="lg:grid grid-cols-12 xl:gap-x-10">
          <div className={wrapperItemsClassNames}>
            <a
              className={classNames(itemClassNames, {
                [itemActiveClassNames]: !activeCategoryId || userClickCategoryId === 'all',
              })}
              href={pageRootPath}
              onClick={(event) => handleClick('all', event)}
            >
              All articles
            </a>
            {categories.map(({ id, name, slug }, index) => {
              const url = pageRootPath + slug;
              const isActive = id === activeCategoryId || id === userClickCategoryId;
              return (
                <a
                  className={classNames(itemClassNames, {
                    [itemActiveClassNames]: isActive,
                  })}
                  href={url}
                  key={index}
                  onClick={(event) => handleClick(id, event)}
                >
                  {name}
                </a>
              );
            })}
          </div>

          <div
            className={classNames(
              'relative flex items-center justify-between align-top w-full py-5 text-center px-8 md:px-14 rounded-4xl border border-gray-2',
              {
                'lg:hidden': categories.length > 4,
                'md:hidden': categories.length <= 4,
              }
            )}
          >
            <select
              className="absolute top-0 left-0 w-full h-full opacity-0"
              value={selectValue}
              onChange={handleSelectChange}
            >
              <option value="all">All Articles</option>
              {categories.map(({ id, name }, index) => (
                <option value={id} key={index}>
                  {name}
                </option>
              ))}
            </select>
            <div className="text-lg md:text-xl font-bold">{selectedCategoryName}</div>
            <ArrowBottom />
          </div>
        </div>
      </Container>
    </div>
  );
};

Categories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageRootPath: PropTypes.string.isRequired,
  activeCategoryId: PropTypes.string,
};

Categories.defaultProps = {
  activeCategoryId: null,
};

export default Categories;
