import PropTypes from 'prop-types';
import React from 'react';

import ArticleCard from 'components/shared/article-card';
import Container from 'components/shared/container';

const Grid = ({ items }) => (
  <section className="mt-5 md:mt-16">
    <Container>
      <div className="grid grid-cols-12 xl:gap-x-10">
        <div className="relative col-start-1 col-end-13 xl:col-start-2 xl:col-end-12">
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 sm:gap-x-6 md:gap-x-8 2xl:gap-x-11 gap-y-11 2xl:gap-y-16">
            {items?.map((props, index) => <ArticleCard {...props} key={index} />) ?? null}
          </div>
        </div>
      </div>
    </Container>
  </section>
);

Grid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default Grid;
